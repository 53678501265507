export default [
  {
    path: "/field-visits",
    name: "field-visits.list",
    meta: { title: "Visites", authRequired: true },
    component: () => import("../views/field-visits/list"),
  },
  {
    path: "/field-visits/:id?/show",
    name: "field-visits.details",
    meta: { title: "Visite détails", authRequired: true },
    component: () => import("../views/field-visits/details"),
  },
];
